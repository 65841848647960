:root {
  --oswald: Oswald, Arial, Georgia, sans-serif;
  --opensans: Open Sans,sans-serif;
}

* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
html,
body {
  font-size: 10px;
}

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: relative;
  min-height: 400px;
  padding: 8rem 0 6rem;
  color: rgb(243, 240, 233);
}

.header img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 25% 10%;
}

.header_data {
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header_title {
  font-family: var(--oswald);
  font-size: 6rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: .02em;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .header_title {
    font-size: 3em;
  }
}

.header_wrap {
  position: relative;
  display: flex;
  justify-content: center;
}

.header_clear {
  border: none;
  background: transparent;
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: 2em;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #222;
}

.header_input {
  font-size: 20px;
  padding: 14px 44px 14px 20px;
  width: 400px;
  outline: none;
  font-family: var(--opensans);
}

@media screen and (max-width: 480px) {
  .header_wrap {
    width: 100%;
  }
  .header_input {
    width: 100%;
  }
}

.header_lang {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.header_lang_link {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header_lang_link svg {
  width: 24px;
  height: 24px;
}

.header_lang_drop {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  top: 60px;
  right: 0px;
  background: #fff;
  color: #999;
  width: 240px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  line-height: 40px;
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,.2);
}
.header_lang_drop:before {
  content: '';
  position: absolute;
  top: -20px;
  right: 14px;
  border: 10px solid transparent;
  border-bottom-color: #f9f9f9;
}
.header_lang_drop li {
  cursor: pointer;
  padding-left: 20px;
  transition: color .2s;
}

.header_lang_drop li:hover {
  color: #333;
}
.header_lang_link-active {
  color: #c4202b;
}

.main {
  flex: 1;
  padding: 0 20px 20px 20px;
  position: relative;
  background:
    url('/public/background-home.jpg') no-repeat 0 -10px/100% auto,
    url('/public/background-dark-grey-noise.jpg') repeat 0 0;
  /* background-size: cover; */
}

/* .main:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vw;
  background-image: url('/public/background-home.jpg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
} */

.main_items {
  position: relative;
  /* font-size: 1.4rem; */
  color: #d5d2c9;
  font-family: var(--opensans);
  /* font-weight: 600; */
  list-style-type: none;
  max-width: 840px;
  padding: 6rem 0;
  margin: 0 auto;
}

.main_item {
  margin: 3rem 0;
}

.main_title {
  font-family: var(--oswald);
  font-weight: 400;
  color: #c49250;
  font-size: 2.3rem;
  margin: .2em 0 .4em;
  text-transform: uppercase;
  letter-spacing: .02em;
}

.main_title:hover .main_icon {
  visibility: visible;
}

.main_anchor {
  padding-left: 4px;
}

.main_icon {
  fill: #c49250;
  visibility: hidden;
}

.main_text {
  color: #d5d2c9;
  font-size: 1.8rem;
}

.divider {
  position:relative;
  display:block;
  overflow:hidden;
  border-top:1px solid #99664d;
  padding-top:.4em;
  width:100%;
  font-size:14px;
  margin: 3rem 0;
}

.main_item:last-child .divider {
  display: none;
}

.divider:after,
.divider:before {
 content:"";
 display:block;
 position:absolute;
 height:.4em;
 width:.5em;
 left:0;
 bottom:100%;
 background:#99664d;
 transform-origin:left bottom;
 transform:rotate(20deg)
}
.divider:after {
 left:auto;
 right:0;
 transform-origin:right bottom;
 transform:rotate(-20deg)
}

.refs {
  font-size: 1.5rem;
}
.refs_title {
  margin-top: 0;
  margin-bottom: 6px;
  font-weight: normal;
}
.refs_items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.refs_btn {
  font-size: 1.5rem;
  color: cadetblue;
  text-decoration-style: dotted;
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all .3s;
}
.refs_btn:hover {
  color: #62c7ca;
}
.refs_item:after {
  content: ',';
  margin-right: 10px;
}
.refs_item:last-child:after {
  content: '';
  margin: 0;
}
.refs_img {
  position: absolute;
  /* top: 20px; */
  bottom: 20px;
  left: 0;
  z-index: 10;
  width: 300px;
  height: auto;
  display: none;
}
@media screen and (max-width: 480px) {
  .refs_img {
    width: 100%;
    bottom: 60px;
  }
}
.refs_btn:hover + .refs_img {
  display: block;
}


footer {
  padding: 24px;
  font-size: 13px;
  background: #111;
  color: #f9f9f9;
}

footer ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  footer ul {
    flex-direction: column;
  }
}

footer li {
  margin: 0 20px;
}

@media screen and (max-width: 480px) {
  footer li {
    margin-bottom: 10px;
  }
}

footer a {
  color: #737373;
  display: flex;
  align-items: center;
  line-height: 18px;
  transition: all .3s;
}

footer a:hover {
  color: #f9f9f9;
}

footer a:hover svg {
  fill: #f9f9f9;
}

footer svg {
  width: 16px;
  height: 16px;
  fill: #737373;
  margin-left: 10px;
}
